import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import cn from 'classnames';
import * as Yup from 'yup';
import { Redirect, Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrimaryButton, ProfileTag, PasswordWrapper, colors } from '@tradetrax/web-common';
import { useAppContext } from '../AppContext';

export const SetPassword = ({ match }) => {
  const { appController, modal } = useAppContext();
  const [redirectTo, setRedirect] = useState(null);
  const [user, setUser] = useState(null);
  const { token } = match.params;

  useEffect(() => {
    appController
      .validateResetToken(token)
      .then(setUser)
      .catch(() => setRedirect('/forgot'));
  }, [appController, setRedirect, token]);

  return (
    <SetPasswordWrapper>
      {redirectTo && <Redirect to={redirectTo} />}
      {user === null && (
        <h5>
          <span className="spinner-grow text-success mr-3"></span>Validating Token
        </h5>
      )}
      {user && <SetPasswordForm {...{ user, appController, token, setRedirect, modal }} />}
    </SetPasswordWrapper>
  );
};

const SetPasswordWrapper = styled.div`
  width: 320px;
  margin-top: 50px;
`;

const schema = Yup.object().shape({
  domain: Yup.string(),
  username: Yup.string().when(['isReset', 'domain'], {
    is: (isReset, domain) => !isReset && domain === 'installer',
    then: Yup.string()
      .required('Username is required')
      .matches(/^[a-zA-Z0-9.\s]+$/, 'Character not allowed')
      .matches(/^(?!\.).*/, 'Can not begin with a period')
      .matches(/^.*[^.]$/, 'Can not end with a period')
      .matches(/^[^\s]+$/, 'Space not allowed')
      .min(6, 'Min. 6 characters')
      .max(30, 'Max. 30 characters'),
  }),
  password: Yup.string()
    .required('Password is required')
    .matches(/^[a-zA-Z0-9~!@#$%^&*_\-+=`|\\(){}[\]:;'<>,.?/]+$/, 'Character not allowed')
    .matches(/^.*[0-9].*$/, 'Must include at least one number')
    .matches(/^.*[a-zA-Z].*$/, 'Must include at least one letter')
    .matches(/^.*[A-Z].*$/, 'Must include at least one uppercase letter')
    .matches(/^.*[a-z].*$/, 'Must include at least one lowercase letter')
    .matches(
      /^.*[~!@#$%^&*_\-+=`|\\(){}[\]:;'<>,.?/].*$/,
      "Must include at least one special character ~!@#$%^&*_-+=`|\\(){}[]:;'<>,.?/"
    )
    .min(7, 'Min. 7 characters')
    .max(64, 'Max. 64 characters'),
  passwordConfirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords do not match'),
});

const SetPasswordForm = ({ user, appController, token, setRedirect }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [eulaCheckBox, setEulaCheckBox] = useState(false);
  const { handleSubmit, register, errors, formState, getValues, setError, trigger, clearErrors } = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: {
      password: '',
      passwordConfirmation: '',
      username: user.username,
      domain: user.domain,
    },
  });
  const hasErrors = Object.keys(errors).length > 0;
  const isReset = user && user.isReset;
  const isInstaller = user.domain === 'installer';
  const isSetCredentials = !isReset && isInstaller;

  const handleError = err => {
    if (err.params) {
      const [error] = err.params;
      const message =
        error?.param === 'username' && err.type === 'entity-conflict'
          ? 'Username is already taken. Please choose another one.'
          : err.detail;
      err.params.forEach(error => setError(error.param, { type: 'manual', message }));
    } else {
      const message = 'There was a problem adding this user. Please try again.';
      setError('backend', { type: 'manual', message: err.detail || message });
    }
    console.error(err);
  };
  const onSubmit = async ({ password, username }) => {
    try {
      if (isSetCredentials) {
        await appController.setCredentials({ token, password, username });
      } else {
        await appController.setPassword({ token, password, isReset });
      }

      if (isInstaller) {
        redirectToLandingPage();
      } else {
        setRedirect('/login');
      }
    } catch (err) {
      handleError(err);
    }
  };

  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const togglePasswordConfirmVisibility = () => setShowPasswordConfirm(!showPasswordConfirm);
  const { username, password, passwordConfirmation } = getValues();
  const title = !isReset ? (isInstaller ? 'Set Profile' : 'Set Password') : 'Reset Password';

  return (
    <SetPwdWrapper>
      {!isReset && <AlertMsg />}
      <div className="welcomeMsg">
        <div className="greenBar"></div>
        <h1 className="mt-2 font-weight-bold">Welcome!</h1>
      </div>
      <div className="d-flex justify-content-start ml-3 mt-4">
        <ProfileTag user={user} />
      </div>
      <h5 className="font-weight-bold">{title}</h5>
      <Form noValidate validated={formState.isValid} onSubmit={handleSubmit(onSubmit)}>
        {isSetCredentials && (
          <Form.Group className="mb-2 pb-1">
            <input type="hidden" ref={register} name="domain" />
            <input type="hidden" ref={register} name="isReset" />
            <Form.Label>Username</Form.Label>
            <Form.Control
              size="lg"
              name="username"
              autoComplete="new-password"
              ref={register}
              isInvalid={!!errors.username}
              isValid={username && !errors.username}
            />
            <Form.Control.Feedback type="invalid">
              <div className="d-flex flex-row">
                <FontAwesomeIcon icon="circle-exclamation" className="mt-1 mr-1" />
                {errors.username?.message}
              </div>
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid">
              <FontAwesomeIcon icon="circle-check" />
              {` Username is ready to go`}
            </Form.Control.Feedback>
          </Form.Group>
        )}
        <Form.Group className="mb-4 pb-1">
          <Form.Label>Password</Form.Label>
          <PasswordWrapper onClick={togglePasswordVisibility} isPlain={showPassword}>
            <Form.Control
              type={showPassword ? 'text' : 'password'}
              name="password"
              size="lg"
              autoComplete="new-password"
              ref={register}
              isInvalid={!!errors.password}
              isValid={password && !errors.password}
              onChange={() => {
                clearErrors('backend');
                return trigger('passwordConfirmation');
              }}
            />
            <Form.Control.Feedback type="invalid">
              <FontAwesomeIcon icon="circle-exclamation" />
              {` ${errors.password?.message}`}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid">
              <FontAwesomeIcon icon="circle-check" />
              {` Password is ready to go`}
            </Form.Control.Feedback>
          </PasswordWrapper>
        </Form.Group>

        <Form.Group controlId="formBasicPassword">
          <Form.Label>Re-enter Password</Form.Label>
          <PasswordWrapper onClick={togglePasswordConfirmVisibility} isPlain={showPasswordConfirm}>
            <Form.Control
              type={showPasswordConfirm ? 'text' : 'password'}
              name="passwordConfirmation"
              size="lg"
              autoComplete="new-password"
              ref={register}
              isInvalid={!!errors.passwordConfirmation}
              isValid={!!passwordConfirmation && !errors.passwordConfirmation}
              onChange={() => {
                clearErrors('backend');
                return trigger('passwordConfirmation');
              }}
            />
            <Form.Control.Feedback type="invalid">
              <FontAwesomeIcon icon="circle-exclamation" />
              {` ${errors.passwordConfirmation?.message}`}
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid">
              <FontAwesomeIcon icon="circle-check" />
              {` Passwords match`}
            </Form.Control.Feedback>
          </PasswordWrapper>
        </Form.Group>

        {!isReset && (
          <div className="d-flex mt-5">
            <CheckBox className="mt-3" type="checkbox" onChange={() => setEulaCheckBox(!eulaCheckBox)} />
            <div className="ml-2 mt-0 mb-2 font-size-12 text-muted">
              <span>By checking the box, I accept the TradeTrax </span>
              <br />
              <ButtonLink href="https://tradetrax.com/privacy-policy" target="_blank">
                Privacy Policy
              </ButtonLink>
              ,{' '}
              <ButtonLink href="https://tradetrax.com/terms-of-use" target="_blank">
                Terms of Use
              </ButtonLink>{' '}
              and{' '}
              <ButtonLink href="https://tradetrax.com/end-user-license-agreement" target="_blank">
                End-User License Agreement.
              </ButtonLink>
            </div>
          </div>
        )}
        <div className="d-flex justify-content-end">
          <PrimaryButton type="submit" disabled={hasErrors || !formState.isDirty || (!isReset && !eulaCheckBox)}>
            {title}
          </PrimaryButton>
        </div>
        <Link className="mt-4 d-inline-block font-size-14" to="/login">
          Back to Login
        </Link>
      </Form>
    </SetPwdWrapper>
  );
};

const SetPwdWrapper = styled.div`
  .welcomeMsg {
    max-width: 340px;
    .greenBar {
      border: 4px solid ${colors.green300};
      width: 38px;
    }
  }
`;

const AlertMsg = () => {
  const [show, setShow] = React.useState(true);
  return (
    <AlertContainer>
      <div className={cn('alert  alert-dismissible fade ', { show: show })}>
        You have been invited to Tradetrax, Set your Profile to create your account.
        <button type="button" className="close" onClick={() => setShow(false)}>
          <span>&times;</span>
        </button>
      </div>
    </AlertContainer>
  );
};

const AlertContainer = styled.div`
  left: 7%;
  top: 34px;
  position: absolute;
  width: 86%;
  .alert {
    text-align: center;
    background-color: ${colors.blue_30};
  }
`;

function redirectToLandingPage() {
  const host = global.location.hostname.replace(/^my./, 'installer.');
  const { protocol } = window.location;
  const url = `${protocol}//${host}`;
  global.location.replace(url);
}

const ButtonLink = styled.a`
  cursor: pointer;
  color: ${colors.blue300};
  &:hover {
    text-decoration: underline;
  }
`;

const CheckBox = styled.input`
  height: 18px;
  width: 18px;
  margin-top: 8px;
`;
