import React, { useContext, useEffect } from 'react';
import { fromJS } from 'immutable';
import { DialogController, useController } from '@tradetrax/web-common';
import * as actions from './AppActions';

export const AppContext = React.createContext(null);
export const useAppContext = () => useContext(AppContext);
export const emptyUser = fromJS({
  firstName: '',
  lastName: '',
  email: '',
  username: '',
});
const emptyState = fromJS({ user: emptyUser, cookieBannerAccepted: true });
const isReset = global.location.pathname.startsWith('/reset/');

const SESSION_ERROR_MAP = {
  'user-deactived': 'Session is terminated because your user account has been deactivated. Please contact your Super.',
  'user-locked':
    'Session is terminated because your user account has been temporarily locked. Please reset your password or contact your Super.',
  'user-deleted': 'Session is terminated because your user account has been deleted. Please contact your Super.',
};

export function UsersContext(continueURL, timeout, sessionError) {
  const miniAlertsRef = React.useRef();
  const alert = miniAlertsRef.current;
  const modal = DialogController();
  const [appState, appController] = useController(actions, emptyState, {
    successMessage,
    errorMessage,
    alert,
    miniAlertsRef,
    continueURL,
  });
  const currentUser = appState.get('user');
  const areCookiesAccepted = appState.get('cookieBannerAccepted');
  const isLoggedIn = currentUser !== null && currentUser !== emptyUser;
  const { acceptCookies } = appController;

  useEffect(() => {
    if (currentUser === emptyUser) {
      if (!isReset) appController.readUserInfo();
    }
    appController.getCookies();
  }, [currentUser, appController]);

  useEffect(() => {
    if (timeout) {
      const message =
        SESSION_ERROR_MAP[sessionError] || 'Session timed out for security reasons. Please log in again to continue.';
      miniAlertsRef.current.warning({ message });
    }
  }, [timeout, sessionError, miniAlertsRef]);

  useEffect(() => {
    if (!areCookiesAccepted) {
      miniAlertsRef.current.showAcceptCookiesBanner(acceptCookies);
      setTimeout(() => {
        global.dispatchEvent(new Event('resize'));
      }, 100);
    }
  }, [areCookiesAccepted, miniAlertsRef, acceptCookies]);

  if (global.Cypress) {
    global.isAlertReady = !!alert;
  }

  return {
    alert,
    miniAlertsRef,
    modal,
    appState,
    appController,
    isLoggedIn,
  };
}

function errorMessage(message) {
  return error => {
    if (this.alert) {
      this.alert.error({ message });
    }
    // return state => state;
    throw error;
  };
}

function successMessage(message) {
  return response => {
    if (this.alert) {
      this.alert.success({ message: message || response.message });
    }
  };
}
