import React from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import { UsersContext, emptyUser, AppContext, useAppContext } from './AppContext';
import { Dialog, MiniAlerts } from '@tradetrax/web-common';
import { Home, Login, Forgot, Token, SetPassword } from './pages';
import * as Layout from './layout';

function App({ continueURL, timeout, sessionError }) {
  const appContext = UsersContext(continueURL, timeout, sessionError);
  const { miniAlertsRef } = appContext;

  return (
    <AppContext.Provider value={appContext}>
      <Dialog controller={appContext.modal} />
      <MiniAlerts ref={miniAlertsRef} />
      <Layout.Content continueURL={continueURL}>
        <Router>
          <Switch>
            <Route component={Login} exact path="/login" />
            <Route component={Forgot} exact path="/forgot" />
            <Route component={Token} exact path="/token/:token" />
            <Route component={SetPassword} exact path="/reset/:token" />
            <PrivateRoute component={Home} path="/" />
          </Switch>
        </Router>
      </Layout.Content>
    </AppContext.Provider>
  );
}

function PrivateRoute(props) {
  const { appState } = useAppContext();
  const user = appState.get('user');

  if (user === emptyUser) {
    return <div>Loading...</div>;
  }

  if (user === null) {
    return <Redirect to="/login" />;
  }

  return <Route {...props} />;
}

export default App;
