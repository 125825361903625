import React, { useState } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { PrimaryButton, wait } from '@tradetrax/web-common';
import { useAppContext } from '../AppContext';
import styled from 'styled-components';
import { colors } from '@tradetrax/web-common/lib/theme';

const schema = yup
  .object()
  .shape({
    email: yup.string().required('Email is required'),
    sendingMethod: yup.string().required(),
  })
  .test('viaTest', null, obj => {
    if (obj.sendingMethod) return true;
    return new yup.ValidationError('Please select one option', null, 'sendingMethod');
  });

const ForgotWrapper = styled.div`
  width: 400px;
  margin-top: 100px;
  padding-left: 28px;
  padding-right: 21px;

  @media (min-width: 768px) {
    width: 380px;
    padding-left: 0;
    padding-right: 0;
  }
  @media (max-width: 1199px) {
    margin-top: 0;
  }
`;

const ForgotTitle = styled.h5`
  font-weight: bold;
  margin-bottom: 20px;
`;

const ForgotButton = styled(PrimaryButton)`
  padding: 0 25px;
  @media (min-width: 768px) {
    padding: 0 32px;
  }
`;

export const Forgot = () => {
  const { appController, alert } = useAppContext();
  const { magicLink, resetPassword, readCommunicationChannel } = appController;
  const [state, setStep] = useState({
    step: 'form',
    sendingMethod: 'viaEmail',
  });
  const { step, email, method, sendingMethod, phone } = state;
  const { handleSubmit, register, errors, formState, trigger, getValues } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: { email: '', sendingMethod: 'viaEmail' },
  });

  const toggleCheck = name => e => setStep({ ...state, sendingMethod: name });

  const getForm = inputValue => {
    const user = {};
    const isEmail = inputValue.indexOf('@') > 0;
    user[isEmail ? 'email' : 'username'] = inputValue;
    return user;
  };

  const onSelectMethod = async (operation = magicLink) => {
    const method = operation === magicLink ? 'magic-link' : 'reset-password';
    const result = await trigger();
    const { email } = getValues();
    if (!email) return;
    const user = getForm(email);
    const { email: hasEmail, phone: hasPhone, hasError } = await readCommunicationChannel(user);
    if (result && hasEmail && hasPhone) {
      setStep({ ...state, method, step: 'selectMethod' });
    } else if (hasEmail || hasPhone) {
      doSendRequest({ user, method, hasEmail, hasPhone });
    } else if (!hasError) {
      alert.error({
        message: (
          <>
            <b className="mr-1">Contact your Super. </b> You haven't set an email or phone number we can use. Please
            contact your super and ask for a password reset.
          </>
        ),
      });
    }
  };

  const onSubmit = () => async () => {
    const result = await trigger();
    if (result) {
      const { email } = getValues();
      const user = getForm(email);
      setStep({ step: 'sending' });
      await wait(1000);
      doSendRequest({ user, method });
    }
  };

  const doSendRequest = async ({ user, method, hasEmail = false, hasPhone = false }) => {
    const operation = method === 'magic-link' ? magicLink : resetPassword;
    const viaEmail = sendingMethod === 'viaEmail' || hasEmail;
    const viaSMS = sendingMethod === 'viaSMS' || hasPhone;
    let via = '';
    if (!sendingMethod) {
      if (hasEmail) via = 'viaEmail';
      if (hasPhone) via = 'viaSMS';
    }
    setStep({ step: 'sending' });
    await wait(1000);
    operation(user, viaEmail, viaSMS)
      .then(({ phone, email }) => setStep({ phone, email, method, step: 'sent', sendingMethod: sendingMethod || via }))
      .catch(() => setStep({ step: 'form' }));
  };

  const showForm = step === 'form';
  const showMessage = step === 'sent';
  const showSpinner = step === 'sending';
  const showSelectMethod = step === 'selectMethod';
  const viaEmail = sendingMethod === 'viaEmail';
  const viaSMS = sendingMethod === 'viaSMS';
  const isDisabled = !(viaSMS || viaEmail);
  return (
    <ForgotWrapper>
      <Form noValidate validated={formState.isValid} onSubmit={handleSubmit(onSubmit())}>
        <div
          className={cn({
            'd-block': showForm,
            'd-none': !showForm,
          })}
        >
          <ForgotTitle>Forgot Password</ForgotTitle>
          <p>We can send you instructions to either reset your password or log in immediately with a Magic Link.</p>
          <Form.Group className="mb-4">
            <Form.Label>Email</Form.Label>
            <Form.Control
              placeholder="user@email.com"
              name="email"
              ref={register}
              isInvalid={!!errors.email}
              onChange={() => trigger('email')}
            />
            <Form.Control.Feedback type="invalid">
              <div className="d-flex align-items-center">
                <FontAwesomeIcon icon="circle-exclamation" />
                <span className="ml-2">{errors.email && errors.email.message}</span>
              </div>
            </Form.Control.Feedback>
            <Form.Control.Feedback type="valid">
              <div className="d-flex align-items-center">
                <FontAwesomeIcon icon="check-circle" />
                <span className="ml-2">{`Email or Username is ready to go`}</span>
              </div>
            </Form.Control.Feedback>
          </Form.Group>
          <div className="d-flex justify-content-between mb-4">
            <ForgotButton onClick={() => onSelectMethod(resetPassword)} variant="secondary">
              Reset Password
            </ForgotButton>
            <ForgotButton className="mb-3 mb-lg-0" onClick={() => onSelectMethod()}>
              Magic Link
            </ForgotButton>
          </div>
          <Link to="/login">Back to Login</Link>
        </div>
        {showSelectMethod && (
          <>
            <p className="h5 text-center my-3 font-weight-bold px-5">I want to receive the information via:</p>
            <Form.Group className="d-flex flex-row justify-content-center mb-4">
              <Form.Check
                checked={sendingMethod === 'viaSMS'}
                className="mr-4"
                type="radio"
                label="SMS"
                ref={register}
                onChange={toggleCheck('viaSMS')}
              />
              <Form.Check
                checked={sendingMethod === 'viaEmail'}
                className="mr-4"
                type="radio"
                label="Email"
                ref={register}
                onChange={toggleCheck('viaEmail')}
              />
              <Form.Control.Feedback type="invalid">
                <div className="d-flex align-items-center">
                  <FontAwesomeIcon icon="circle-exclamation" />
                  <span className="ml-2">{errors.email && errors.email.message}</span>
                </div>
              </Form.Control.Feedback>
              <Form.Control.Feedback type="valid">
                <div className="d-flex align-items-center">
                  <FontAwesomeIcon icon="circle-check" />
                  <span className="ml-2">{`Email or Username is ready to go`}</span>
                </div>
              </Form.Control.Feedback>
            </Form.Group>
            <div className="d-flex justify-content-around mt-3">
              <PrimaryButton onClick={onSubmit()} disabled={isDisabled}>
                Send
              </PrimaryButton>
            </div>
            <Button variant="link" onClick={() => setStep({ step: 'form' })}>
              Back
            </Button>
          </>
        )}
        {showSpinner && (
          <div className="d-flex justify-content-center">
            <div className="spinner-border text-success"></div>
          </div>
        )}
        {showMessage && (
          <ContentMsg>
            <p className="text-center">
              {viaEmail && <EmailOpen />}
              {viaSMS && <SMSOpen />}
            </p>
            {viaEmail && <p className="h4 text-center mt-4 font-weight-bold">Check your email</p>}
            {viaSMS && <p className="h4 text-center mt-4 font-weight-bold">Check your phone</p>}
            <p className="text-center mt-3 mb-5">
              <InstructiveMessage method={method} email={email} phone={phone} viaEmail={viaEmail} viaSMS={viaSMS} />
            </p>
          </ContentMsg>
        )}
      </Form>
    </ForgotWrapper>
  );
};

const ContentMsg = styled.div`
  width: 400px;
  background-color: ${colors.white};
  box-shadow: 0px 1px 2px rgba(51, 51, 51, 0.2);
  border-radius: 20px;
  padding: 40px;
`;
const InstructiveMessage = ({ method, viaSMS, viaEmail, phone, email }) => {
  const phoneEncrypted = phone?.replace(/^(\+?\d+)(\d{2})$/, function() {
    return arguments[1].replace(/./g, '*') + arguments[2];
  });
  if (method === 'magic-link' && viaEmail)
    return (
      <>
        We just emailed you a magic link for an instant login to <strong>{email}</strong>.
      </>
    );
  else if (method === 'reset-password' && viaEmail)
    return (
      <>
        We just emailed instructions to reset your password to <strong>{email}</strong>.
      </>
    );
  else if (method === 'magic-link' && viaSMS)
    return (
      <>
        We just texted you a magic link for an instant login to your phone <strong>{phoneEncrypted}</strong>.
      </>
    );
  else if (method === 'reset-password' && viaSMS)
    return (
      <>
        We just texted you instructions to reset your password to your phone <strong>{phoneEncrypted}</strong>.
      </>
    );
  else return <>We just sent you instructions to reset your password.</>;
};

const EmailOpen = () => (
  <svg width="84" height="83" viewBox="0 0 84 83" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <g filter="url(#filter0_d)">
        <circle cx="42" cy="40" r="40" fill="#BFE4DA" />
      </g>
      <path
        d="M49.5 35.375V34.125C49.5 33.8125 49.1875 33.5 48.875 33.5H35.125C34.7344 33.5 34.5 33.8125 34.5 34.125V35.375C34.5 35.7656 34.7344 36 35.125 36H48.875C49.1875 36 49.5 35.7656 49.5 35.375ZM35.125 31H48.875C49.1875 31 49.5 30.7656 49.5 30.375V29.125C49.5 28.8125 49.1875 28.5 48.875 28.5H35.125C34.7344 28.5 34.5 28.8125 34.5 29.125V30.375C34.5 30.7656 34.7344 31 35.125 31ZM60.5938 28.8906C60.4375 28.7344 58.875 27.4844 57 25.9219V23.5C57 22.1719 55.8281 21 54.5 21H50.8281C50.5156 20.7656 50.2031 20.5312 50.0469 20.375C48.3281 19.0469 45.3594 16 42 16C38.5625 16 35.6719 18.9688 33.875 20.375C33.7188 20.5312 33.4062 20.7656 33.0938 21H29.5C28.0938 21 27 22.1719 27 23.5V25.9219C25.0469 27.4844 23.4844 28.7344 23.3281 28.8906C22.5469 29.5156 22 30.7656 22 31.7812V52.25C22 54.3594 23.6406 56 25.75 56H58.25C60.2812 56 62 54.3594 62 52.25V31.7812C62 30.6875 61.4531 29.5938 60.5938 28.8906ZM42 18.5C43.6406 18.5 45.4375 19.8281 46.9219 21H37C38.4844 19.8281 40.2812 18.5 42 18.5ZM29.5 23.5H54.5V37.0938C51.9219 39.125 49.3438 41.1562 47.7031 42.4844C46.375 43.5781 43.7969 46.0781 42 46C40.125 46.0781 37.5469 43.5781 36.2188 42.4844C34.5781 41.1562 32 39.125 29.5 37.0938V23.5ZM24.5 31.7812C24.5 31.3906 24.6562 31.0781 24.8906 30.8438C25.0469 30.7656 25.8281 30.0625 27 29.2031V35.0625C26.0625 34.3594 25.2031 33.6562 24.5 33.0312V31.7812ZM59.5 52.25C59.5 52.9531 58.875 53.5 58.25 53.5H25.75C25.0469 53.5 24.5 52.9531 24.5 52.25V36.2344C27.7812 38.9688 32.2344 42.4844 34.6562 44.4375C36.2969 45.7656 39.1094 48.5781 42 48.5C44.8125 48.5781 47.625 45.7656 49.2656 44.4375C51.6875 42.4844 56.1406 38.9688 59.5 36.2344V52.25ZM59.5 33.0312C58.7188 33.6562 57.8594 34.3594 57 35.0625V29.2031C58.0938 30.0625 58.875 30.7656 59.0312 30.8438C59.2656 31.0781 59.5 31.3906 59.5 31.7812V33.0312Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d"
        x="0"
        y="-1"
        width="84"
        height="84"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
      <clipPath id="clip0">
        <path d="M0 0H84V83H0V0Z" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const SMSOpen = () => (
  <svg width="85" height="83" viewBox="0 0 85 83" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <g filter="url(#filter0_d)">
        <circle cx="42.5" cy="40" r="40" fill="#BFE4DA" />
      </g>
      <path
        d="M47 23.5V42.25H37V23.5H47ZM50.75 16H33.25C31.1406 16 29.5 17.7188 29.5 19.75V52.25C29.5 54.3594 31.1406 56 33.25 56H50.75C52.7812 56 54.5 54.3594 54.5 52.25V19.75C54.5 17.7188 52.7812 16 50.75 16ZM33.25 53.5C32.5469 53.5 32 52.9531 32 52.25V19.75C32 19.125 32.5469 18.5 33.25 18.5H50.75C51.375 18.5 52 19.125 52 19.75V52.25C52 52.9531 51.375 53.5 50.75 53.5H33.25ZM48.5625 21H35.4375C34.8906 21 34.5 21.4688 34.5 21.9375V43.8125C34.5 44.3594 34.8906 44.75 35.4375 44.75H48.5625C49.0312 44.75 49.5 44.3594 49.5 43.8125V21.9375C49.5 21.4688 49.0312 21 48.5625 21ZM44.8125 48.5H39.1875C38.6406 48.5 38.25 48.9688 38.25 49.4375V50.0625C38.25 50.6094 38.6406 51 39.1875 51H44.8125C45.2812 51 45.75 50.6094 45.75 50.0625V49.4375C45.75 48.9688 45.2812 48.5 44.8125 48.5Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d"
        x="0.5"
        y="-1"
        width="84"
        height="84"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0 0.2 0 0 0 0.2 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
      </filter>
      <clipPath id="clip0">
        <rect width="84" height="83" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);
