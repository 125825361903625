import React from 'react';
import { useAppContext } from '../AppContext';

export function Home() {
  const { appState } = useAppContext();
  const domain = appState.getIn(['user', 'domain']);
  const host = window.location.hostname.replace(/^my./, `${domain}.`);
  const { protocol } = window.location;
  const url = `${protocol}//${host}`;
  setTimeout(() => {
    window.location.replace(url);
  });

  return <div>Redirecting...</div>;
}
