import React from 'react';
import { usersService } from '../services';
import { markAsSideEffect, markAsSync } from '@tradetrax/web-common';
import { readCookies } from '@tradetrax/web-common/lib/utils';
import { fromJS } from 'immutable';

const ERRORS_MAP = {
  'login-invalid-state': 'Your user account has been deactivated. Please contact your Super.',
  'login-invalid-credentials': 'The username and password do not match our records. Please try again.',
  'bad-request-generic': 'There was a problem logging you in. Please try again.',
  'not-data': (
    <>
      <b>Contact your Admin.</b> You haven’t set an email or phone number. Please contact your admin and ask for a
      password reset
    </>
  ),
  'password-same-as-previous': `Error, please type a password you haven't used before`,
  'entity-not-found': 'The email or username do not exist in our records. Please try again or contact your Super.',
  'only-username-reset-password-for-installer': 'As an installer you must use your username. Please try again.',
  'only-username-for-installer': 'As an installer you must use your username. Please try again.',
  'invalid-user-status': `Your user account has been deactivated. Please contact your Super.`,
  'invalid-user-status-homeowner': 'Your user is not yet active. Please log in first to request a reset or magic link.',
  'invalid-token': (
    <>
      Link to reset Password has expired. Click on <strong>Reset Password</strong> to request a new one.
    </>
  ),
  'login-invalid-token': (
    <>
      Magic link has expired. Click on <strong>Magic Link</strong> to request a new one.
    </>
  ),
  'user-locked-warning':
    'Too many failed login attempts. Your user account is close to being locked. You may want to reset your password now.',
  'user-is-locked':
    'Your user account has been temporarily locked due to multiple failed login attempts. Please reset your password or contact your Super.',
  'magic-link-locked':
    'Your user account has been temporarily locked. Please reset your password or contact your Super.',
  'magic-link-expired':
    'Magic link has expired because your user account has been temporarily locked. Please reset your password or contact your Super.',
};

export async function readUserInfo() {
  try {
    const user = await usersService.info();
    return state => state.set('user', user ? fromJS(user) : null);
  } catch {
    return state => state.set('user', null);
  }
}
export async function logout() {
  await usersService.logout();
  return state => state.set('user', null);
}

export function login({ email, password }) {
  const form = { password };
  const isEmail = email.indexOf('@') > 0;
  form[isEmail ? 'email' : 'username'] = email;
  return usersService
    .login(form)
    .then(redirectUser(this.continueURL))
    .catch(err => {
      const message = ERRORS_MAP[err.type] || 'There was a problem logging you in. Please try again.';
      const isFiveAttemptsError = err.type === 'login-invalid-credentials' && err.hasLoginAttemptWarning;
      if (isFiveAttemptsError) this.miniAlertsRef.current.warning({ message: ERRORS_MAP['user-locked-warning'] });
      else this.miniAlertsRef.current.error({ message });
      return state => state;
    });
}

export function loginToken(token) {
  return usersService
    .login({ token })
    .then(redirectUser(this.continueURL))
    .catch(err => {
      const message = ERRORS_MAP[err.type] || (
        <>
          There was a problem logging you in with this link. Please try again or click on{' '}
          <strong className="mx-1">Forgot Password</strong> to request a new one.
        </>
      );
      const isUserLocked = err.type === 'user-is-locked';
      if (isUserLocked) this.miniAlertsRef.current.warning({ message: ERRORS_MAP['magic-link-expired'] });
      else this.miniAlertsRef.current.error({ message });
      throw err;
    });
}

markAsSideEffect(magicLink, resetPassword, validateResetToken, setPassword, setCredentials, readCommunicationChannel);

export function magicLink(user, viaEmail, viaSMS) {
  return usersService.magicLink(user, { query: { sms: viaSMS, email: viaEmail } }).catch(err => {
    const message = ERRORS_MAP[err.type] || 'There was a problem with this request. Please try again.';
    if (err.type === 'user-is-locked') this.miniAlertsRef.current.warning({ message: ERRORS_MAP['magic-link-locked'] });
    else this.miniAlertsRef.current.error({ message });
    throw err;
  });
}

export function resetPassword(user, viaEmail, viaSMS) {
  return usersService.resetPassword(user, { query: { sms: viaSMS, email: viaEmail } }).catch(err => {
    const message = ERRORS_MAP[err.type] || 'There was a problem with this request. Please try again.';
    this.miniAlertsRef.current.error({ message, purge: true });
    throw err;
  });
}

export async function readCommunicationChannel(user) {
  return usersService.checkCommunicationChannels(user).catch(err => {
    const isHomeowner = err.domain === 'homeowner';
    const message =
      (isHomeowner && err.type === 'invalid-user-status' && ERRORS_MAP['invalid-user-status-homeowner']) ||
      ERRORS_MAP[err.type] ||
      'There was a problem with this request. Please try again.';
    this.miniAlertsRef.current.error({ message });
    return { email: false, phone: false, hasError: true };
  });
}

export function validateResetToken(token) {
  return usersService
    .validateResetToken({}, { params: { token } })
    .then(user => {
      this.controller.dispatch([state => state.set('user', user)]);
      return user;
    })
    .catch(err => {
      const message = ERRORS_MAP[err.type] || 'There was a problem with this request. Please try again.';
      this.miniAlertsRef.current.error({ message });
      throw err;
    });
}

export function setPassword({ token, password, isReset }) {
  const successText = isReset ? 'reset' : 'created';
  return usersService
    .changePassword({ token, password })
    .then(this.successMessage(`Password successfully ${successText}. Log in to continue.`))
    .catch(err => {
      const message = ERRORS_MAP[err.type] || 'There was a problem with this request. Please try again.';
      this.miniAlertsRef.current.error({ message });
      throw err;
    });
}

export function setCredentials({ token, password, username }) {
  return usersService
    .setCredentials({ token, password, username })
    .then(this.successMessage(`Password and Username successfully created. Log in to continue.`))
    .catch(this.errorMessage('There was a problem with this request. Please try again.'));
}

export function acceptCookies() {
  return usersService.acceptCookieBanner().then(() => {
    this.miniAlertsRef.current.pop();
    return state => state.set('cookieBannerAccepted', true);
  });
}

markAsSync(getCookies);
export function getCookies(state) {
  const cookies = readCookies();
  return state.set('cookieBannerAccepted', !!cookies['cookie-banner-accepted']);
}

function redirectUser(continueURL) {
  return response => {
    if (response) {
      if (continueURL.pathname === '/login') {
        window.location.replace(continueURL.origin);
      } else if (continueURL.href) {
        window.location.replace(continueURL.href);
      } else if (continueURL) {
        window.location.replace(continueURL);
      } else {
        const { domain } = response;
        const host = window.location.hostname.replace(/^my./, `${domain}.`);
        const { protocol } = window.location;
        const url = `${protocol}//${host}`;
        window.location.replace(url);
      }
      return state => state.set('user', fromJS(response));
    }

    return state => state;
  };
}
