import React from 'react';
import styled from 'styled-components';
import queryString from 'query-string';
import { Logo, TradeTrax, colors } from '@tradetrax/web-common';
import { readCookies } from '@tradetrax/web-common/lib/utils';
import { useAppContext } from '../AppContext';

const qs = queryString.parse(global.location.search);

const Content = ({ continueURL, children }) => {
  return (
    <ContentWrapper>
      <LeftSide continueURL={continueURL} />
      <RightSide>{children}</RightSide>
    </ContentWrapper>
  );
};

const ContentWrapper = styled.div`
  min-height: 100vh;
  width: 100%;
  background-color: ${colors.pearl};
  display: flex;

  @media (max-width: 1199px) {
    display: block;
  }
`;

const LeftSide = ({ continueURL }) => {
  const { appState } = useAppContext();
  const info = getContent(appState, continueURL);
  return (
    <LeftSideWrapper background={info.bgImage} bgPosition={info.bgPosition}>
      <div className="leftSideContainer">
        <div className="logoWrapper">
          <Logo className="mr-3" />
          <TradeTrax />
        </div>
        <h1 className="title mt-n3">{info.title}</h1>
        <p className="phrase mt-4">{info.description}</p>
      </div>
    </LeftSideWrapper>
  );
};

const getContent = (appState, continueURL) => {
  const getUserType = () => {
    if (continueURL) return continueURL?.match(/(?!\/\/)[a-z]+(?=\.)/)[0];
    const cookies = readCookies();
    return cookies['userType'];
  };
  const userType = (appState.getIn(['user', 'domain']) || qs.userType) ?? getUserType();

  if (userType === 'builder' || userType === 'sub')
    return {
      title: 'Building processes, building great projects.',
      description: 'Let us help you revolutionize construction teams and improve your processes and scheduling.',
      bgImage: '/images/builder-trades.jpg',
      bgPosition: '70% ',
    };

  if (userType === 'homeowner')
    return {
      title: 'Your Home from a better view.',
      description:
        "With the Homeowner Portal don't miss a thing and keep an eye on your new Home no matter where you are.",
      bgImage: '/images/home-owner.jpg',
      bgPosition: '55%',
    };

  return {
    title: 'Unlock the Future of Homebuilding.',
    description:
      'With TradeTrax, build homes faster and better than ever before—leaving incremental progress in the rearview mirror.',
    bgImage: '/images/general.jpg',
    bgPosition: '55% ',
  };
};

const LeftSideWrapper = styled.div`
  ${({ background, bgPosition }) => `
    min-height: 100%;
    width: 40wv;
    background: ${colors.gray800};
    background-image: url('${background}'); 
    background-size: cover;
    background-position: ${bgPosition};
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    color: #fff;
    padding: 0 100px;

    .leftSideContainer {
      max-width: 390px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      .logoWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 190px 0 120px 0;
      }
      .title {
        font-weight: bold;
      }
      .phrase {
        font-size: 22px;
      }
    }

    @media (max-width:1199px) {
      height: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
      
      .title,.phrase {
        display:none;
      }

      .leftSideContainer {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-content: center;
        
        .logoWrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          margin:0; 
        }
      
      }
    }


  `};
`;

const RightSide = styled.div`
  height: 100%;
  width: 60vw;
  display: flex;
  justify-content: center;
  margin-top: 200px;
  padding-bottom: 20px;

  @media (max-width: 1199px) {
    width: 100%;
    height: auto;
    margin-top: 67px;
  }
`;

export default Content;
