import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
import App from './app/App';
import * as serviceWorker from './serviceWorker';
import queryString from 'query-string';
import '@tradetrax/web-common/scss/_tradetrax.scss';
import '@tradetrax/web-common/lib/font-awesome';

const qs = queryString.parse(window.location.search);
const continueURL = qs.continueURL || ''; //window.location;
const timeout = qs.timeout === '1';
const sessionError = qs.sessionErrorCode;

ReactDOM.render(
  <App continueURL={continueURL} timeout={timeout} sessionError={sessionError} />,
  document.getElementById('wrapper')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
