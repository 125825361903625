import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import Layout from './Layout';
import { useAppContext } from '../AppContext';

export const Token = ({ match }) => {
  const { appController } = useAppContext();
  const [redirectTo, setRedirect] = useState(null);
  const { token } = match.params;

  useEffect(() => {
    appController.loginToken(token).catch(() => setRedirect('/forgot'));
  }, [appController, setRedirect, token]);

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }
  return (
    <Layout>
      <h5>
        <span className="spinner-grow text-success mr-3"></span>Validating Token
      </h5>
    </Layout>
  );
};
