import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { yupResolver } from '@hookform/resolvers';
import * as yup from 'yup';
import { PrimaryButton, PasswordWrapper, colors } from '@tradetrax/web-common';
import { useAppContext } from '../AppContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import queryString from 'query-string';
import { readCookies } from '@tradetrax/web-common/lib/utils';

const schema = yup.object().shape({
  email: yup.string().required('Email or Username is required'),
  password: yup.string().required('Password is required'),
});
const qs = queryString.parse(global.location.search);

export const Login = props => {
  const { appController, appState } = useAppContext();
  const { handleSubmit, register, errors } = useForm({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
    defaultValues: { password: '', email: '' },
  });
  const [showPassword, setShowPassword] = useState(false);
  const onSubmit = form => {
    appController.login(form);
  };
  const togglePasswordVisiblity = () => setShowPassword(showPassword ? false : true);
  const continueURL = props.location.search;

  const getUserType = () => {
    if (continueURL) return continueURL?.match(/(?!\/\/)[a-z]+(?=\.)/)[0];
    const cookies = readCookies();
    return cookies['userType'];
  };
  const userTypeTest = (appState.getIn(['user', 'domain']) || qs.userType) ?? getUserType();
  const hasErrors = Object.keys(errors).length > 0;
  const loginPhrase =
    userTypeTest === 'homeowner' ? 'Log in to start following you Home progress.' : 'Log in anytime, anywhere';

  return (
    <LoginWrapper>
      <div className="welcomeMsg">
        <div className="greenBar"></div>
        <h1 className="mt-2 font-weight-bold">Welcome!</h1>
        <p className="mt-3" style={{ width: '320px' }}>
          {loginPhrase}
        </p>
        <h5 className="mt-5 font-weight-bold">Login</h5>
        <Form noValidate onSubmit={handleSubmit(onSubmit)}>
          <Form.Group className="mb-4 pb-3">
            <Form.Label>Username</Form.Label>
            <Form.Control
              size="lg"
              placeholder="user@email.com"
              name="email"
              ref={register}
              isInvalid={!!errors.email}
            />
            <Form.Control.Feedback type="invalid">
              <div className="d-flex align-items-center">
                <FontAwesomeIcon icon="circle-exclamation" />
                <span className="ml-2">{errors.email?.message}</span>
              </div>
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <PasswordWrapper onClick={togglePasswordVisiblity} isPlain={showPassword}>
              <Form.Control
                type={showPassword ? 'text' : 'password'}
                name="password"
                size="lg"
                ref={register}
                isInvalid={!!errors.password}
              />
              <Form.Control.Feedback type="invalid">
                <div className="d-flex align-items-center">
                  <FontAwesomeIcon icon="circle-exclamation" />
                  <span className="ml-2">{errors.password?.message}</span>
                </div>
              </Form.Control.Feedback>
            </PasswordWrapper>
          </Form.Group>

          <Link to="/forgot" className="d-inline-block mt-5">
            Forgot Password?
          </Link>
          <div className="d-flex justify-content-end">
            <LoginButton type="submit" disabled={hasErrors}>
              Log In
            </LoginButton>
          </div>
        </Form>
      </div>
    </LoginWrapper>
  );
};

const LoginButton = styled(PrimaryButton)`
  width: 128px;
  border: none;
  display: inline-block;
  background-color: ${colors.green300};
`;

const LoginWrapper = styled.div`
  .welcomeMsg {
    max-width: 320px;
    .greenBar {
      border: 4px solid ${colors.green300};
      width: 38px;
    }
  }
`;
