import React from 'react';
import { Logo, TradeTrax, colors } from '@tradetrax/web-common';
import styled from 'styled-components';

function useMiniAlertMargin() {
  const [height, setHeight] = React.useState(0);

  React.useEffect(() => {
    const miniAlert = window.document.getElementById('mini-alert');
    if (miniAlert?.clientHeight !== height) setHeight(miniAlert.clientHeight);

    const onResize = () => {
      if (miniAlert) setHeight(miniAlert.clientHeight);
    };

    global.addEventListener('resize', onResize);
    return () => global.removeEventListener('resize', onResize);
  }, [height, setHeight]);

  return height;
}

const UserLayout = ({ children, width = '400px', className = '' }) => {
  const height = useMiniAlertMargin();

  return (
    <Layout className="d-flex justify-content-center align-items-center something-here" miniAlertHeight={height}>
      <ContentWrapper className="d-flex flex-column align-items-center">
        <LogoWrapper className="d-flex justify-content-center align-items-center mt-5">
          <Logo className="mr-3" />
          <TradeTrax />
        </LogoWrapper>
        <Content className={`d-block mx-auto ${className}`} width={width}>
          {children}
        </Content>
      </ContentWrapper>
    </Layout>
  );
};

const Layout = styled.div`
  ${props => `
  margin-top: 2.5rem;
  ${props.miniAlertHeight > 0 ? `margin-bottom: ${props.miniAlertHeight + 50}px;` : ''}
`}
`;

const Content = styled.div`
  ${props => `
    width: ${props.width};
  `}
  border-radius: 20px;
  background: ${colors.pearl};
  margin-top: 2rem;
  padding: 2.5rem;

  @media (max-width: 576px) {
    width: 90%;
  }
`;

const ContentWrapper = styled.div`
  @media (max-width: 576px) {
    width: 100%;
  }
`;

const LogoWrapper = styled.div`
  @media (max-width: 576px) {
    width: 45%;
  }
`;


export default UserLayout;
